import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogOpenChangeData,
  DialogOpenChangeEvent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Input,
  Tag,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  Tooltip,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { FormEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { MAX_GROUP_MEMBER_COUNT } from '../constants'
import { useGroupDialog } from '../hooks/useGroupDialog'
import { Bearer, Group } from '../util/group'
import { Stream } from '../util/stream'

const useStyles = makeStyles({
  tag: {
    maxWidth: '30ch',
    display: 'block',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
  label: {
    width: 'fit-content',
  },
  dialogActions: {
    justifyContent: 'end',
  },
})

type GroupDialogProps = {
  onOpenChange: (
    _event: DialogOpenChangeEvent,
    data: DialogOpenChangeData
  ) => void
  onSubmit: (group: Group) => void
  group?: Group
  streams: Stream[]
}

export function GroupDialog({
  onOpenChange,
  onSubmit,
  group,
  streams,
}: GroupDialogProps) {
  const styles = useStyles()
  const { t } = useTranslation()
  const {
    groupName,
    bearers,
    selectedOptions,
    tagPickerOptions,
    bearersExist,
    isCreatingGroup,
    onOptionSelect,
    handleNameChange,
  } = useGroupDialog({ group, streams })

  return (
    <Dialog open={true} onOpenChange={onOpenChange}>
      <DialogSurface>
        <form onSubmit={handleSubmit}>
          <DialogBody className={styles.dialogBody}>
            <DialogTitle>
              {isCreatingGroup
                ? t('streams.create-group')
                : t('streams.edit-group')}
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <div className={styles.field}>
                <label className={styles.label} htmlFor="group-name">
                  {t('streams.group-name')}
                </label>
                <Input
                  id="group-name"
                  onChange={handleNameChange}
                  value={groupName}
                />
              </div>
              <div className={styles.field}>
                <label className={styles.label} htmlFor="camera-users">
                  {t('streams.camera-users')}
                </label>
                <Tooltip
                  relationship={'description'}
                  positioning={'below-end'}
                  content={bearersExist ? '' : t('streams.no-one-streaming')}
                  visible={bearersExist ? false : undefined}
                >
                  <TagPicker
                    disabled={!bearersExist}
                    onOptionSelect={onOptionSelect}
                    selectedOptions={selectedOptions}
                  >
                    <TagPickerControl>
                      <TagPickerGroup
                        aria-label={t('streams.selected-camera-users')}
                      >
                        <BearerTags />
                      </TagPickerGroup>
                      <TagPickerInput
                        id="camera-users"
                        aria-label={t('streams.select-camera-users')}
                      />
                    </TagPickerControl>
                    <TagPickerList>
                      <BearerTagOptions />
                    </TagPickerList>
                  </TagPicker>
                </Tooltip>
              </div>
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">
                  {t('common.action.cancel')}
                </Button>
              </DialogTrigger>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  onClick={handleClick}
                  type="submit"
                  appearance="primary"
                >
                  {isCreatingGroup
                    ? t('common.action.create')
                    : t('common.action.save')}
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )

  function BearerTags() {
    return selectedOptions.map((option) => (
      <Tag
        type="button"
        key={option}
        shape="rounded"
        media={
          <Avatar
            aria-hidden
            color="colorful"
            name={bearers.get(option)?.name ?? t('streams.unknown-user')}
          />
        }
        value={option}
      >
        <div className={styles.tag}>
          {bearers.get(option)?.name ?? t('streams.unknown-user')}
        </div>
      </Tag>
    ))
  }

  function BearerTagOptions() {
    if (tagPickerOptions.length === 0) {
      return (
        <TagPickerOption value="no-options">
          {t('streams.no-options-available')}
        </TagPickerOption>
      )
    }

    if (selectedOptions.length === MAX_GROUP_MEMBER_COUNT) {
      return (
        <TagPickerOption value="no-options">
          {t('streams.max-number-of-users')}
        </TagPickerOption>
      )
    }

    return tagPickerOptions.map((option) => (
      <TagPickerOption
        media={
          <Avatar
            shape="square"
            aria-hidden
            color="colorful"
            name={bearers.get(option)?.name ?? t('streams.unknown-user')}
          />
        }
        value={option}
        key={option}
        text={''}
      >
        <div className={styles.tag}>
          {bearers.get(option)?.name ?? t('streams.unknown-user')}
        </div>
      </TagPickerOption>
    ))
  }

  // Prevent refresh on form submission
  function handleSubmit(event: FormEvent) {
    event.preventDefault()
  }

  // Since the edit group dialog is conditionally rendered, it is not present in
  // the DOM once open state changes. Form submission occurs after dialog open
  // change event. This results in the form submission being called on a non
  // existing form and the event is therefor cancelled. In order to retain the
  // desired form behavior, primary action button on enter etc, the form should
  // remain. However, the submit logic should instead be called on primary
  // action button click.
  function handleClick() {
    const selectedBearers = selectedOptions.reduce((acc, bearerId) => {
      const bearer = bearers.get(bearerId) ?? group?.bearers.get(bearerId)

      if (bearer) {
        acc.set(bearerId, bearer)
      }

      return acc
    }, new Map<string, Bearer>())

    // Generate group id if no group, i.e a new group should be created
    const groupId = group?.id ?? crypto.randomUUID()

    onSubmit({
      id: groupId,
      name: groupName,
      bearers: selectedBearers,
    })
  }
}
