/** Return default language code for a country based on the country's ISO code.
 * */
const DEFAULT_LANGUAGE_CODES = {
  AF: 'ps_AF',
  AL: 'sq_AL',
  DZ: 'ar_DZ',
  AS: 'en_AS',
  AD: 'ca',
  AO: 'pt',
  AI: 'en',
  AQ: 'en_US',
  AG: 'en',
  AR: 'es_AR',
  AM: 'hy_AM',
  AW: 'nl',
  AU: 'en_AU',
  AT: 'de_AT',
  AZ: 'az_Cyrl_AZ',
  BS: 'en',
  BH: 'ar_BH',
  BD: 'bn_BD',
  BB: 'en',
  BY: 'be_BY',
  BE: 'nl_BE',
  BZ: 'en_BZ',
  BJ: 'fr_BJ',
  BM: 'en',
  BT: 'dz',
  BO: 'es_BO',
  BQ: 'nl',
  BR: 'pt_BR',
  IO: 'en',
  BN: 'ms_BN',
  BG: 'bg_BG',
  BF: 'fr_BF',
  BI: 'fr_BI',
  CV: 'kea_CV',
  KH: 'km_KH',
  CM: 'fr_CM',
  CA: 'en_CA',
  KY: 'en',
  CF: 'fr_CF',
  TD: 'fr_TD',
  CL: 'es_CL',
  CN: 'zh_CN',
  CX: 'en',
  CC: 'en',
  CO: 'es_CO',
  KM: 'fr_KM',
  CD: 'fr_CD',
  CG: 'fr_CG',
  CK: 'en',
  CR: 'es_CR',
  HR: 'hr_HR',
  CU: 'es',
  CW: 'nl',
  CY: 'el_CY',
  CZ: 'cs_CZ',
  CI: 'fr_CI',
  DK: 'da_DK',
  DJ: 'fr_DJ',
  DM: 'en',
  DO: 'es_DO',
  EC: 'es_EC',
  EG: 'ar_EG',
  SV: 'es_SV',
  GQ: 'fr_GQ',
  ER: 'ti_ER',
  EE: 'et_EE',
  SZ: 'en',
  ET: 'am_ET',
  FK: 'en',
  FO: 'fo_FO',
  FJ: 'en',
  FI: 'fi_FI',
  FR: 'fr_FR',
  GF: 'fr',
  PF: 'fr',
  TF: 'fr',
  GA: 'fr_GA',
  GM: 'en',
  GE: 'ka_GE',
  DE: 'de_DE',
  GH: 'ak_GH',
  GI: 'en',
  GR: 'el_GR',
  GL: 'kl_GL',
  GD: 'en',
  GP: 'fr_GP',
  GU: 'en_GU',
  GT: 'es_GT',
  GG: 'en',
  GN: 'fr_GN',
  GW: 'pt_GW',
  GY: 'en',
  HT: 'fr',
  HM: 'en',
  VA: 'it',
  HN: 'es_HN',
  HK: 'en_HK',
  HU: 'hu_HU',
  IS: 'is_IS',
  IN: 'hi_IN',
  ID: 'id_ID',
  IR: 'fa_IR',
  IQ: 'ar_IQ',
  IE: 'en_IE',
  IM: 'en',
  IL: 'he_IL',
  IT: 'it_IT',
  JM: 'en_JM',
  JP: 'ja_JP',
  JE: 'en',
  JO: 'ar_JO',
  KZ: 'kk_Cyrl_KZ',
  KE: 'ebu_KE',
  KI: 'en',
  KP: 'ko',
  KR: 'ko_KR',
  KW: 'ar_KW',
  KG: 'ky',
  LA: 'lo',
  LV: 'lv_LV',
  LB: 'ar_LB',
  LS: 'en',
  LR: 'en',
  LY: 'ar_LY',
  LI: 'de_LI',
  LT: 'lt_LT',
  LU: 'fr_LU',
  MO: 'zh_Hans_MO',
  MG: 'fr_MG',
  MW: 'en',
  MY: 'ms_MY',
  MV: 'dv',
  ML: 'fr_ML',
  MT: 'en_MT',
  MH: 'en_MH',
  MQ: 'fr_MQ',
  MR: 'ar',
  MU: 'en_MU',
  YT: 'fr',
  MX: 'es_MX',
  FM: 'en',
  MD: 'ro_MD',
  MC: 'fr_MC',
  MN: 'mn',
  ME: 'sr_Cyrl_ME',
  MS: 'en',
  MA: 'ar_MA',
  MZ: 'pt_MZ',
  MM: 'my_MM',
  NA: 'en_NA',
  NR: 'en',
  NP: 'ne_NP',
  NL: 'nl_NL',
  AN: 'nl_AN',
  NC: 'fr',
  NZ: 'en_NZ',
  NI: 'es_NI',
  NE: 'fr_NE',
  NG: 'ha_Latn_NG',
  NU: 'en',
  NF: 'en',
  MK: 'mk_MK',
  MP: 'en_MP',
  NO: 'nb_NO',
  OM: 'ar_OM',
  PK: 'en_PK',
  PW: 'en',
  PS: 'ar',
  PA: 'es_PA',
  PG: 'en',
  PY: 'es_PY',
  PE: 'es_PE',
  PH: 'en_PH',
  PN: 'en',
  PL: 'pl_PL',
  PT: 'pt_PT',
  PR: 'es_PR',
  QA: 'ar_QA',
  RO: 'ro_RO',
  RU: 'ru_RU',
  RW: 'fr_RW',
  RE: 'fr_RE',
  BL: 'fr_BL',
  SH: 'en',
  KN: 'en',
  LC: 'en',
  MF: 'fr_MF',
  PM: 'fr',
  VC: 'en',
  WS: 'sm',
  SM: 'it',
  ST: 'pt',
  SA: 'ar_SA',
  SN: 'fr_SN',
  RS: 'sr_Cyrl_RS',
  SC: 'fr',
  SL: 'en',
  SG: 'en_SG',
  SX: 'nl',
  SK: 'sk_SK',
  SI: 'sl_SI',
  SB: 'en',
  SO: 'so_SO',
  ZA: 'af_ZA',
  GS: 'en',
  SS: 'en',
  ES: 'es_ES',
  LK: 'si_LK',
  SD: 'ar_SD',
  SR: 'nl',
  SJ: 'no',
  SE: 'sv_SE',
  CH: 'fr_CH',
  SY: 'ar_SY',
  TW: 'zh_Hant_TW',
  TJ: 'tg',
  TZ: 'asa_TZ',
  TH: 'th_TH',
  TL: 'pt',
  TG: 'fr_TG',
  TK: 'en',
  TO: 'to_TO',
  TT: 'en_TT',
  TN: 'ar_TN',
  TR: 'tr_TR',
  TM: 'tk',
  TC: 'en',
  TV: 'en',
  UG: 'cgg_UG',
  UA: 'uk_UA',
  AE: 'ar_AE',
  GB: 'en_GB',
  UM: 'en_UM',
  US: 'en_US',
  UY: 'es_UY',
  UZ: 'uz_Cyrl_UZ',
  VU: 'bi',
  VE: 'es_VE',
  VN: 'vi_VN',
  VG: 'en',
  VI: 'en_VI',
  WF: 'fr',
  EH: 'es',
  YE: 'ar_YE',
  ZM: 'bem_ZM',
  ZW: 'en_ZW',
  AX: 'sv',
  XK: 'sq',
}

type CountryIsoCode = keyof typeof DEFAULT_LANGUAGE_CODES

export function getDefaultLanguageCode(countryIsoCode: string) {
  if (countryIsoCode in DEFAULT_LANGUAGE_CODES) {
    return DEFAULT_LANGUAGE_CODES[countryIsoCode as CountryIsoCode]
  }

  return 'en'
}
