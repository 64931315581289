import { Temporal } from '@js-temporal/polyfill'

export interface Bearer {
  id: string
  name: string
}
export interface Group {
  id: string
  bearers: Map<string, Bearer>
  name?: string
  triggerTimestamp?: Temporal.ZonedDateTime
  decryptable?: boolean
  viewed?: boolean
}

interface LocalStorageGroup extends Omit<Group, 'bearers'> {
  bearers: Record<string, Bearer>
}

// Retrieve record of groups from browser local storage.
export function getCachedGroups() {
  try {
    const cachedGroups = localStorage.getItem('groups')
    if (cachedGroups) {
      const deserializedGroups: Record<string, LocalStorageGroup> =
        JSON.parse(cachedGroups)
      const groups = new Map<string, Group>()

      // Convert bearers to map separately since deserialization will result in
      // empty nested Map by default.
      Object.values(deserializedGroups).forEach((group) => {
        groups.set(group.id, {
          ...group,
          bearers: bearersFromRecordToMap(group.bearers),
        })
      })

      return groups
    }
  } catch (error) {
    console.warn('Failed parsing cached groups')
  }
  return new Map<string, Group>()
}

// Store record of groups in browser local storage.
export function setCachedGroups(groups: Map<string, Group>) {
  try {
    localStorage.setItem('groups', JSON.stringify(groups, groupsReplacer))
  } catch (error) {
    console.warn('Failed saving cached groups')
  }
}

export function bearerIdsFromStringToList(bearerIds: string) {
  return bearerIds.split(/; ?/).filter((x) => x)
}

export function bearerIdsFromListToString(bearerIds: string[]) {
  return bearerIds.join(';')
}

// Returns group from local storage or undefined if group doesn't exist.
export function getCachedGroup(id: string): Group | undefined {
  const groups = getCachedGroups()

  return groups.get(id)
}

export function groupNameLabel(group: Group) {
  if (group.name) {
    return group.name
  }

  const bearerNames = [...group.bearers.values()].map((bearer) => bearer.name)

  return bearerNames.join(', ')
}

// Store record of pinned groups in browser local storage.
export function setCachedPinnedIds(ids: string[]) {
  try {
    localStorage.setItem('pinnedIds', JSON.stringify(ids))
  } catch (error) {
    console.warn('Failed saving cached pinned groups')
  }
}

// Retrieve record of pinned groups from browser local storage.
export function getCachedPinnedIds() {
  try {
    const pinnedIds = localStorage.getItem('pinnedIds')
    if (pinnedIds) {
      return JSON.parse(pinnedIds) as string[]
    }
  } catch (error) {
    console.warn('Failed parsing cached groups')
  }
  return []
}

// Explicitly convert nested bearers map to object since nested map will be
// serialized to empty object by default.
function groupsReplacer(
  _key: string,
  value: Group | string | Map<string, Bearer>
) {
  if (value instanceof Map) {
    return Object.fromEntries(value)
  } else {
    return value
  }
}

function bearersFromRecordToMap(bearers: Record<string, Bearer>) {
  const deserializedBearers = new Map<string, Bearer>()

  Object.values(bearers).forEach((bearer) => {
    deserializedBearers.set(bearer.id, { id: bearer.id, name: bearer.name })
  })

  return deserializedBearers
}
