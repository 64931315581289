import {
  NoMatchDark,
  NoMatchLight,
} from '@axiscommunications/fluent-illustrations'
import { useFlag } from '@axteams-one/bws-cloud-flags/react'
import { Position } from '@axteams-one/bws-cloud-maps/layers/tracking'
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleData,
  AccordionToggleEvent,
  Body2,
  DialogOpenChangeData,
  DialogOpenChangeEvent,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { Temporal } from '@js-temporal/polyfill'
import { DragEvent, MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'

import { useThemeId } from '../providers/ThemeProvider'
import { Flags } from '../util/flags'
import { Group, getCachedPinnedIds, setCachedPinnedIds } from '../util/group'
import { subjectFromBearerId } from '../util/map'
import { Stream } from '../util/stream'
import { GroupDialog } from './GroupDialog'
import GroupItem from './GroupItem'
import { RemoveGroupDialog } from './RemoveGroupDialog'
import StreamItem from './StreamItem'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // Style selected stream item
    '& .active': {
      backgroundColor: tokens.colorNeutralBackground1Selected,
      '& span': {
        color: tokens.colorNeutralForeground1,
      },
    },
    overflow: 'auto',
  },
  noMatchesContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingInlineStart: tokens.spacingHorizontalS,
    paddingBlock: tokens.spacingVerticalXL,
  },
  noMatchesText: {
    color: tokens.colorNeutralForegroundDisabled,
  },
  illustration: {
    width: '120px',
    minWidth: '120px',
  },
  recentItemsContainer: {
    paddingInlineStart: tokens.spacingHorizontalS,
  },
})

type StreamListProps = {
  streams: Stream[]
  filteredStreams: Stream[]
  positions: Position[]
  groups: Group[]
  selectedGroupId: string | null
  hoveredStreamId: string | null
  onGroupMapButtonClick: (groupId: string) => void
  onGroupClick: (event: MouseEvent<HTMLDivElement>, groupId: string) => void
  onGroupPointerOver: (group: Group) => void
  onEditGroup: (group: Group) => void
  onRemoveGroup: (group: Group) => void
  onStreamMapButtonClick: (stream: Stream) => void
  onStreamClick: (event: MouseEvent<HTMLAnchorElement>, stream: Stream) => void
  onStreamPointerOver: (stream: Stream) => void
  onStreamDrag: (event: DragEvent<HTMLAnchorElement>, stream: Stream) => void
  onGroupDrop: (groupId: string) => void
  onStreamDrop: (stream: Stream) => void
  onPointerLeave: () => void
}

type StreamsAccordionProps = {
  pinnedItems: Item[]
  recentItems: Item[]
  openSidebarItems: string[]
  handleToggle: (
    _event: AccordionToggleEvent,
    data: AccordionToggleData
  ) => void
  itemMapper: (item: Item) => JSX.Element
}

export type OpenDialog = 'edit' | 'remove' | 'create' | undefined

export interface GroupDialogState {
  openDialog: OpenDialog
  group?: Group
}

export enum ItemType {
  stream,
  group,
}

interface Item {
  id: string
  type: ItemType
  object: Stream | Group
  pinned?: boolean
  triggerTimestamp?: Temporal.ZonedDateTime
}

export function StreamList({
  streams,
  filteredStreams,
  positions,
  groups,
  selectedGroupId,
  hoveredStreamId,
  onGroupMapButtonClick,
  onGroupClick,
  onGroupPointerOver,
  onEditGroup,
  onRemoveGroup,
  onStreamMapButtonClick,
  onStreamClick,
  onStreamPointerOver,
  onStreamDrag,
  onGroupDrop,
  onStreamDrop,
  onPointerLeave,
}: StreamListProps) {
  const styles = useStyles()
  const [themeId] = useThemeId()
  const { t } = useTranslation()
  const enablePinGroups = useFlag(Flags.PIN_GROUPS)?.enabled === true
  const [pinnedIds, setPinnedIds] = useState(() => getCachedPinnedIds())
  const [openSidebarItems, setOpenSidebarItems] = useLocalStorage(
    'openSidebarItems',
    ['pinned', 'recent']
  )

  const [openDialog, setOpenDialog] = useState<OpenDialog>()
  const [group, setGroup] = useState<Group>()

  if (filteredStreams?.length === 0 && groups.length === 0) {
    return (
      <div className={styles.noMatchesContainer}>
        {themeId === 'light' ? (
          <NoMatchLight className={styles.illustration} />
        ) : (
          <NoMatchDark className={styles.illustration} />
        )}
        <Body2 className={styles.noMatchesText}>{t('common.no-matches')}</Body2>
      </div>
    )
  }

  const recentItems: Item[] = []
  const pinnedItems: Item[] = []

  for (const group of groups) {
    const pinned = pinnedIds.includes(group.id)

    const groupItem = {
      id: group.id,
      type: ItemType.group,
      object: group,
      pinned: pinned,
      triggerTimestamp: group.triggerTimestamp,
    }

    if (pinned && enablePinGroups) {
      pinnedItems.push(groupItem)
    } else {
      recentItems.push(groupItem)
    }
  }

  for (const stream of filteredStreams) {
    const streamItem = {
      id: stream.id,
      type: ItemType.stream,
      object: stream,
      triggerTimestamp: stream.metadata.triggerTimestamp,
    }

    recentItems.push(streamItem)
  }

  pinnedItems.sort(sortDescOnTimestamp)
  recentItems.sort(sortDescOnTimestamp)

  return (
    <div className={styles.container} onPointerLeave={onPointerLeave}>
      {pinnedItems.length > 0 ? (
        <StreamsAccordion
          pinnedItems={pinnedItems}
          recentItems={recentItems}
          openSidebarItems={openSidebarItems}
          handleToggle={handleToggle}
          itemMapper={itemMapper}
        />
      ) : (
        <div className={styles.recentItemsContainer}>
          {recentItems?.map(itemMapper)}
        </div>
      )}
      {renderDialog()}
    </div>
  )

  function renderDialog() {
    if (!group || !openDialog) {
      return null
    }

    switch (openDialog) {
      case 'edit':
        return (
          <GroupDialog
            onOpenChange={onDialogOpenChange}
            onSubmit={handleSubmitGroup}
            group={group}
            streams={streams}
          />
        )
      case 'remove':
        return (
          <RemoveGroupDialog
            onOpenChange={onDialogOpenChange}
            onRemoveGroup={onRemoveGroup}
            group={group}
          />
        )
      default:
        return null
    }
  }

  function itemMapper(item: Item) {
    if (item.type === ItemType.stream) {
      const stream = item.object as Stream
      const position = positions.find(
        (position: Position) =>
          position.subject === subjectFromBearerId(stream.bearerId)
      )

      return (
        <StreamItem
          key={stream.id}
          stream={stream}
          position={position}
          hovered={stream.id === hoveredStreamId}
          onMapButtonClick={() => onStreamMapButtonClick(stream)}
          onClick={onStreamClick}
          onPointerOver={onStreamPointerOver}
          onDrop={onStreamDrop}
          onDrag={onStreamDrag}
        />
      )
    }

    const group = item.object as Group

    return (
      <GroupItem
        key={group.id}
        group={group}
        positions={positions}
        selected={group.id === selectedGroupId}
        pinned={item.pinned}
        onOpenDialog={handleOpenDialog}
        onMapButtonClick={onGroupMapButtonClick}
        onClick={onGroupClick}
        onPointerOver={onGroupPointerOver}
        onPin={handlePin}
        onDrop={onGroupDrop}
      />
    )
  }

  function handleOpenDialog(openDialog: OpenDialog, group: Group) {
    setOpenDialog(openDialog)
    setGroup(group)
  }

  function handleSubmitGroup(group: Group) {
    onEditGroup(group)
    setOpenDialog(undefined)
    setGroup(undefined)
  }

  function handlePin(id: string) {
    const updatedPinnedIds = getCachedPinnedIds()
    const pinned = updatedPinnedIds.includes(id)
    if (pinned) {
      setAndCachePinnedIds(
        updatedPinnedIds.filter((pinnedId) => pinnedId !== id)
      )
    } else {
      setAndCachePinnedIds([...updatedPinnedIds, id])
    }
  }

  function setAndCachePinnedIds(ids: string[]) {
    setPinnedIds(ids)
    setCachedPinnedIds(ids)
  }

  function handleToggle(
    _event: AccordionToggleEvent,
    data: AccordionToggleData
  ) {
    setOpenSidebarItems(data.openItems as string[])
  }

  function onDialogOpenChange(
    _event: DialogOpenChangeEvent,
    data: DialogOpenChangeData
  ) {
    if (!data.open) {
      setOpenDialog(undefined)
      setGroup(undefined)
    }
    onPointerLeave()
  }
}

function StreamsAccordion({
  pinnedItems,
  recentItems,
  openSidebarItems,
  handleToggle,
  itemMapper,
}: StreamsAccordionProps) {
  const { t } = useTranslation()

  return (
    <Accordion
      collapsible
      openItems={openSidebarItems}
      onToggle={handleToggle}
      multiple={true}
    >
      <AccordionItem value="pinned">
        <AccordionHeader size="small">
          {t('streams.pinned-groups')}
        </AccordionHeader>
        <AccordionPanel>{pinnedItems?.map(itemMapper)}</AccordionPanel>
      </AccordionItem>
      <AccordionItem value="recent">
        <AccordionHeader size="small">{t('streams.recent')}</AccordionHeader>
        <AccordionPanel>{recentItems?.map(itemMapper)}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

function sortDescOnTimestamp(a: Item, b: Item): number {
  // Prioritize valid trigger timestamp over undefined.
  if (!a.triggerTimestamp || !b.triggerTimestamp) {
    const aCompareValue = a.triggerTimestamp ? 1 : 0
    const bCompareValue = b.triggerTimestamp ? 1 : 0
    return bCompareValue - aCompareValue
  }
  return Temporal.ZonedDateTime.compare(b.triggerTimestamp, a.triggerTimestamp)
}
