import { InputOnChangeData } from '@fluentui/react-components'
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { Group } from '../util/group'
import { Stream } from '../util/stream'

export enum Filter {
  all,
  streams,
  groups,
}

export function useStreamsFilter(
  streams: Stream[] | undefined,
  groups: Group[]
) {
  const [filter, setFilter] = useState<Filter>(Filter.all)
  const [search, setSearch] = useState('')

  // Remaining streams after applying categorical filter and search filter.
  const streamFilter = useCallback(
    (stream: Stream) => {
      return (
        stream.ongoing &&
        (stream.metadata.bearerName || '')
          // TODO: Use locale based lower case
          .toLowerCase()
          .includes(search.toLowerCase())
      )
    },
    [search]
  )
  const [filteredStreams, setFilteredStreams] = useState<Stream[]>([])
  useEffect(
    () =>
      setFilteredStreams(
        filter === Filter.streams || filter === Filter.all
          ? (streams ?? []).filter(streamFilter)
          : []
      ),
    [filter, streams, streamFilter]
  )

  // Remaining groups after applying categorical filter and search filter.
  const groupFilter = useCallback(
    (group: Group) => {
      return (
        // TODO: Use locale based lower case
        (group.name || '').toLowerCase().includes(search.toLowerCase()) ||
        [...group.bearers.values()].some((bearer) =>
          bearer.name.toLowerCase().includes(search.toLowerCase())
        )
      )
    },
    [search]
  )
  const [filteredGroups, setFilteredGroups] = useState<Group[]>([])
  useEffect(
    () =>
      setFilteredGroups(
        filter === Filter.groups || filter === Filter.all
          ? groups.filter(groupFilter)
          : []
      ),
    [filter, groups, groupFilter]
  )

  // Streams present in filteredGroups, i.e. in remaining groups.
  const inFilteredGroupFilter = useCallback(
    (stream: Stream) => {
      return (
        stream.ongoing &&
        filteredGroups.some((group) => group.bearers.has(stream.bearerId))
      )
    },
    [filteredGroups]
  )
  const [streamsInFilteredGroups, setStreamsInFilteredGroups] = useState<
    Stream[]
  >([])
  useEffect(
    () =>
      setStreamsInFilteredGroups(streams?.filter(inFilteredGroupFilter) ?? []),
    [inFilteredGroupFilter, streams]
  )

  const handleFilter = useCallback((filter: Filter) => {
    setFilter((prev) => {
      return prev === filter ? Filter.all : filter
    })
  }, [])

  const handleSearch = useCallback(
    (
      _event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLSpanElement>,
      data: InputOnChangeData
    ) => setSearch(data.value),
    []
  )

  return {
    filter,
    search,
    handleFilter,
    handleSearch,
    filteredStreams,
    filteredGroups,
    streamsInFilteredGroups,
  }
}
