import {
  InputOnChangeData,
  TagPickerOnOptionSelectData,
} from '@fluentui/react-components'
import { ChangeEvent, SyntheticEvent, useState } from 'react'

import { Bearer, Group } from '../util/group'
import { Stream } from '../util/stream'

type UseGroupDialogProps = {
  group?: Group
  streams: Stream[]
}

type GroupDialogProps = {
  groupName: string
  bearers: Map<string, Bearer>
  selectedOptions: string[]
  tagPickerOptions: string[]
  bearersExist: boolean
  isCreatingGroup: boolean
  onOptionSelect: (
    _e: SyntheticEvent | Event,
    data: TagPickerOnOptionSelectData
  ) => void
  handleNameChange: (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => void
}

export function useGroupDialog({
  group,
  streams,
}: UseGroupDialogProps): GroupDialogProps {
  const [groupName, setGroupName] = useState(group?.name ?? '')
  // Since previously selected bearers might no longer exist, i.e. have streamed
  // within the last 24h (retention time), they need to be added to the list of
  // selectable bearers.
  const bearers = streams.reduce((acc, stream) => {
    acc.set(stream.bearerId, {
      id: stream.bearerId,
      name: stream.metadata?.bearerName || '',
    })
    return acc
  }, new Map<string, Bearer>(group?.bearers))

  const [selectedOptions, setSelectedOptions] = useState<string[]>([
    ...(group?.bearers.keys() ?? []),
  ])

  const tagPickerOptions = [...bearers.keys()].filter(
    (option) => !selectedOptions.includes(option)
  )

  const bearersExist = bearers.size !== 0
  const isCreatingGroup = !group

  return {
    groupName,
    bearers,
    selectedOptions,
    tagPickerOptions,
    bearersExist,
    isCreatingGroup,
    onOptionSelect,
    handleNameChange,
  }

  function onOptionSelect(
    _e: SyntheticEvent | Event,
    data: TagPickerOnOptionSelectData
  ) {
    if (data.value === 'no-options') {
      return
    }
    setSelectedOptions(data.selectedOptions)
  }

  function handleNameChange(
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    setGroupName(data.value)
  }
}
