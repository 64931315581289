import { useCallback, useMemo } from 'react'

import { Group } from '../util/group'
import { Stream, compareTriggerTimestamp } from '../util/stream'

type UseExtendedGroupsProps = {
  streams?: Stream[]
  groups: Map<string, Group>
  viewedStreams: string[]
}

export function useExtendedGroups({
  streams,
  groups,
  viewedStreams,
}: UseExtendedGroupsProps) {
  // Extends group with properties 'triggerTimestamp', 'decryptable' and
  // 'viewed'. 'triggerTimestamp' is based on the timestamp of the last
  // triggered group stream, or undefined if no ongoing group stream exist.
  // Viewed is true if all streams have been viewed. Decryptable is true if all
  // streams are decryptable, i.e. decryption key(s) exist for all the streams.
  const extendedGroupMapper = useCallback(
    (group: Group) => {
      const groupStreams =
        streams?.filter(
          (stream) => group.bearers.has(stream.bearerId) && stream.ongoing
        ) ?? []
      groupStreams.sort(compareTriggerTimestamp)

      const triggerTimestamp = groupStreams[0]?.metadata.triggerTimestamp

      const decryptable = !groupStreams.some(
        (stream) => stream.decryptable === false
      )

      const viewed = !groupStreams.some(
        (stream) => !viewedStreams.includes(stream.id)
      )

      return {
        ...group,
        triggerTimestamp,
        decryptable,
        viewed,
      }
    },
    [streams, viewedStreams]
  )

  const extendedGroups = useMemo(
    () => [...groups.values()].map(extendedGroupMapper),
    [extendedGroupMapper, groups]
  )

  return extendedGroups
}
