import {
  Button,
  Slot,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import { Alert } from '@fluentui/react-components/unstable'
import { useCallback, useState } from 'react'

const useStyles = makeStyles({
  default: {
    width: '100%',
    alignItems: 'center',
    gap: tokens.spacingHorizontalXS,
    padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalL}`,
  },
})

/** Options for dispatching an alert. */
export interface AlertOptions {
  action?: Slot<typeof Button>
  className?: string
  /** Include dismiss button. */
  dismissible?: boolean
  /** The number of milliseconds after which the alert is automatically hidden. */
  hideAfter?: number
  intent?: 'info' | 'success' | 'error' | 'warning'
  message: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
  /**
   * Whether or not the alert is unique.
   * A unique alert will only ever be shown if no other alert has the same message.
   */
  unique?: boolean
}

export interface Alert {
  id: string
  options: AlertOptions
  timeout?: ReturnType<typeof setTimeout>
}

/** Dispatch an alert. */
export interface DispatchAlert {
  (options: AlertOptions): Alert
}

function generateRandomId(): string {
  return Math.floor(Math.random() * 1e6).toString()
}

/**
 * An alert management hook.
 * The renderedAlerts of the result contains alert components ready for display.
 * The dispatchAlert function is used to dispatch alerts.
 * @example
 * const {renderedAlerts, dispatchAlert} = useAlerts()
 * useEffect(() => {
 *   dispatchAlert({
 *     message: 'Hello, World!',
 *     intent: 'info',
 *     hideAfter: 3 * 1e3,
 *   })
 * }, [])
 * return renderedAlerts
 */
export function useAlerts(): {
  dispatchAlert: DispatchAlert
  renderedAlerts: JSX.Element[]
  resetAlerts: () => void
  removeAlert: (alert: Alert) => void
} {
  const [alerts, setAlerts] = useState<Alert[]>([])
  const styles = useStyles()

  const removeAlert = useCallback((alert: Alert) => {
    // Clear any potential auto hide timeout
    if (alert.timeout) {
      clearTimeout(alert.timeout)
      alert.timeout = undefined
    }

    setAlerts((alerts) => alerts.filter((x) => x.id !== alert.id))
  }, [])

  const resetAlerts = useCallback(() => {
    setAlerts([])
  }, [])

  const dispatchAlert = useCallback(
    (options: AlertOptions) => {
      const alert: Alert = {
        id: options.unique ? options.message : generateRandomId(),
        options,
      }

      if (options.hideAfter) {
        alert.timeout = setTimeout(() => {
          removeAlert(alert)
        }, options.hideAfter)
      }

      setAlerts((alerts) => {
        // Use the correct state to identify whether or not a duplicate exists
        const exists = alerts.some((x) => x.id === alert.id)
        return exists ? alerts : [...alerts, alert]
      })
      return alert
    },

    [removeAlert]
  )

  const renderedAlerts = alerts.map((alert, i) => (
    <Alert
      className={mergeClasses(alert.options.className, styles.default)}
      key={i}
      intent={alert.options.intent}
      action={alert.options.action}
      onClick={alert.options.onClick}
    >
      {alert.options.message}
    </Alert>
  ))

  return { dispatchAlert, renderedAlerts, resetAlerts, removeAlert }
}
