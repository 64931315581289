import ServiceWorker from '/src/service-worker?url'
import { DiscoveryProvider } from '@axteams-one/bws-cloud-discovery/react'
import { MatomoCollector } from '@axteams-one/bws-cloud-metrics/collectors/matomo'
import { MetricsProvider } from '@axteams-one/bws-cloud-metrics/react'
import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { LoadingPage } from './components/Loading'
import { config, getEnvironment, setConfig } from './config'
import { App } from './pages/App'
import { GroupViewerPage } from './pages/GroupViewerPage'
import { InternalErrorPage } from './pages/InternalErrorPage'
import LandingPage from './pages/LandingPage'
import { LivePlayerPage } from './pages/LivePlayerPage'
import { RecordingPlayerPage } from './pages/RecordingPlayerPage'
import { RecordingsPage } from './pages/RecordingsPage'
import { RecordingsStartPage } from './pages/RecordingsStartPage'
import { SettingsPage } from './pages/SettingsPage'
import { StreamsPage } from './pages/StreamsPage'
import { StreamsStartPage } from './pages/StreamsStartPage'
import { TemporaryGroupViewerPage } from './pages/TemporaryGroupViewerPage'
import './pages/main.css'
import { AlertProvider } from './providers/AlertProvider'
import { ThemeProvider } from './providers/ThemeProvider'
import { AuthenticatedRoute } from './util/AuthenticatedRoute'

showConsoleMotd()
main()

function main() {
  setConfig({ environment: getEnvironment(window.location.hostname) })

  // Register service workers once the app has loaded
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', registerServiceWorker)
  } else {
    registerServiceWorker()
  }

  const collector = new MatomoCollector(
    config.matomo.endpoint,
    config.matomo.siteId
  )

  // TODO We've removed StrictMode because Fluent UI currently doesn't support
  // StrictMode in React 18. As soon as it's supported we should add it back in
  // all applications.
  const container = document.getElementById('root') as HTMLElement
  const root = createRoot(container)

  root.render(
    <StrictMode>
      <ThemeProvider cache={localStorage}>
        <DiscoveryProvider
          endpoint={config.discoveryApiUrl}
          errorPlaceholder={<InternalErrorPage />}
          loadingPlaceholder={<LoadingPage />}
        >
          <AlertProvider>
            <MetricsProvider collector={collector}>
              <Suspense fallback={<LoadingPage />}>
                <BrowserRouter>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <AuthenticatedRoute>
                          <App />
                        </AuthenticatedRoute>
                      }
                    >
                      <Route path="/" element={<LandingPage />} />
                      <Route path="/streams" element={<StreamsPage />}>
                        <Route path="/streams" element={<StreamsStartPage />} />
                        <Route
                          path="/streams/:streamId"
                          element={<LivePlayerPage />}
                        />
                        <Route
                          path="/streams/group/:groupId"
                          element={<GroupViewerPage />}
                        />
                        <Route
                          path="/streams/group/:groupId/:bearerIds"
                          element={<GroupViewerPage />}
                        />
                        <Route
                          path="/streams/group/tmp/:bearerIds"
                          element={<TemporaryGroupViewerPage />}
                        />
                      </Route>
                      <Route path="/recordings" element={<RecordingsPage />}>
                        <Route
                          path="/recordings"
                          element={<RecordingsStartPage />}
                        />
                        <Route
                          path="/recordings/:streamId"
                          element={<RecordingPlayerPage />}
                        />
                      </Route>
                      <Route path="/settings" element={<SettingsPage />} />
                    </Route>
                    <Route path="*" element={<Navigate to="/streams" />} />
                  </Routes>
                </BrowserRouter>
              </Suspense>
            </MetricsProvider>
          </AlertProvider>
        </DiscoveryProvider>
      </ThemeProvider>
    </StrictMode>
  )
}

async function registerServiceWorker() {
  // Unregister all existing service workers
  try {
    await navigator.serviceWorker
      .getRegistrations()
      .then((registrations) =>
        Promise.all(
          registrations.map((registration) => registration.unregister())
        )
      )
  } catch (error) {
    console.warn('Failed to unregister old service workers')
  }

  // Register the service worker
  try {
    await navigator.serviceWorker.register(ServiceWorker, {
      scope: '/',
    })
  } catch (error) {
    console.error('Service worker registration failed', error)
  }
}

function showConsoleMotd() {
  // eslint-disable-next-line no-console
  console.log(`%cWarning`, 'color:red;font-size:2rem')
  // eslint-disable-next-line no-console
  console.log(`This is a browser feature meant for developers.
If you have been told to copy and paste something here you may be the target of fraud.
For more information please refer to https://en.wikipedia.org/wiki/Self-XSS.

For security-related inquiries, please refer to https://www.axis.com/security.txt.`)

  // eslint-disable-next-line no-console
  console.log(
    `Application version: ${
      import.meta.env['VITE_APP_VERSION_LONG'] || 'unknown'
    }.`
  )
}
