import {
  Toast,
  ToastTitle,
  useToastController,
} from '@fluentui/react-components'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounceCallback } from 'usehooks-ts'

import { TOASTER_ID } from '../constants'
import { Stream } from '../util/stream'

type UseStreamToastsProps = {
  streams: Stream[] | undefined
}

const NEW_STREAM_TOAST_TIMEOUT = 5000

/**
 * Takes a list of streams and dispatches a toast every time a new stream is
 * added to the list, e.g. every time a user starts streaming.
 */
export function useStreamToasts({ streams }: UseStreamToastsProps) {
  const { dispatchToast } = useToastController(TOASTER_ID)
  const { t } = useTranslation()

  const previousStreamsRef = useRef<Stream[]>()

  // Define current vs new streams to dictate for which bearers a toast should
  // be dispatched.
  function dispatchNewStreamsToasts() {
    // Streams not yet loaded or no new streams
    if (!streams || previousStreamsRef.current === streams) {
      return
    }

    // First time streams loaded or no streams found
    if (!previousStreamsRef.current || streams.length === 0) {
      previousStreamsRef.current = streams
      return
    }

    const newStreams = streams.filter(
      (stream) =>
        !previousStreamsRef.current?.some(
          (previousStream) => previousStream.id === stream.id
        )
    )
    previousStreamsRef.current = streams

    if (newStreams.length === 0) {
      return
    }
    if (newStreams.length > 4) {
      dispatchToast(
        <Toast>
          <ToastTitle>
            {t('streams.some-camera-users-started-streaming', {
              count: newStreams.length,
            })}
          </ToastTitle>
        </Toast>,
        { intent: 'info', timeout: NEW_STREAM_TOAST_TIMEOUT }
      )
      return
    }
    for (const stream of newStreams) {
      const name = stream.metadata.bearerName
      const toastTitle = stream.metadata.bearerName
        ? t('streams.camera-user-started-streaming', { name })
        : t('streams.unknown-camera-user-started-streaming')

      dispatchToast(
        <Toast>
          <ToastTitle>{toastTitle}</ToastTitle>
        </Toast>,
        { intent: 'info', timeout: NEW_STREAM_TOAST_TIMEOUT }
      )
    }
  }

  // Debounce dispatching toasts for new streams
  const newStreamToastsDebounced = useDebounceCallback(
    dispatchNewStreamsToasts,
    500
  )

  useEffect(() => {
    newStreamToastsDebounced()
  }, [dispatchToast, newStreamToastsDebounced, streams])
}
