import { Client } from '@axteams-one/bws-cloud-azure-web-pubsub'
import { useEffect, useState } from 'react'
import { Client as UrqlClient } from 'urql'

import {
  ErrorCode,
  GetNotificationsWebSocketUrlDocument,
  GetNotificationsWebSocketUrlQuery,
} from '../graphql/__generated__/graphql'
import { ApiResult, ApiStatus, InternalErrorCode } from './types'

type UseNotificationsWebSocketProps = {
  client?: UrqlClient
  organizationId: string
}

/**
 * Establish a WebSocket for consuming notification events of an organization.
 */
export function useNotificationsWebSocket({
  client,
  organizationId,
}: UseNotificationsWebSocketProps) {
  const [result, setResult] = useState<ApiResult<string>>({
    status: ApiStatus.Idle,
  })
  const [error, setError] = useState<
    InternalErrorCode | ErrorCode | undefined
  >()
  const [webPubClient, setWebPubClient] = useState<Client>()
  const [retryCount, setRetryCount] = useState(0)

  useEffect(() => {
    let timeout: NodeJS.Timeout
    function retryPubSubConnection() {
      if (error) {
        // Will re-trigger the websocket query
        setRetryCount((retryCount) => retryCount + 1)

        // Retry the pub sub connection every 2 minutes,
        // if there's an error.
        timeout = setTimeout(retryPubSubConnection, 120_000)
      }
    }

    retryPubSubConnection()

    return () => clearTimeout(timeout)
  }, [error])

  useEffect(() => {
    if (!client || !organizationId) {
      return
    }

    setResult({ status: ApiStatus.Loading })
    client
      .query<GetNotificationsWebSocketUrlQuery>(
        GetNotificationsWebSocketUrlDocument,
        {
          options: { organizationId },
        },
        {
          requestPolicy: 'network-only',
        }
      )
      .toPromise()
      .then((result) => {
        if (result.data) {
          const webSocketUrl = result?.data?.getNotificationsWebSocketUrl.url
          setResult({
            status: ApiStatus.Resolved,
            data: webSocketUrl || '',
          })
        } else if (result.error) {
          const errorCode = result.error.graphQLErrors[0]?.extensions.code
          setResult({
            status: ApiStatus.Rejected,
            error: errorCode as ErrorCode,
          })
        } else {
          // Handle 504 Gateway Timeout and other unspecified errors.
          setResult({
            status: ApiStatus.Rejected,
            error: InternalErrorCode.NetworkError,
          })
        }
      })
      .catch(() => {
        setResult({
          status: ApiStatus.Rejected,
          error: InternalErrorCode.NetworkError,
        })
      })
  }, [client, organizationId, retryCount])

  // Graphql connection string failure
  useEffect(() => {
    // Don't change the error state while the query is loading.
    if (result.status === ApiStatus.Rejected) {
      setError(result.error)
    } else if (result.status === ApiStatus.Resolved) {
      setError(undefined)
    }
  }, [result])

  // Create and connect Azure Web PubSub client once connection string has been fetched.
  useEffect(() => {
    if (result.status !== ApiStatus.Resolved) {
      return
    }

    let client
    try {
      client = new Client(result.data)
    } catch (error) {
      setError(ErrorCode.BwoBadRequest)
      console.error(error, organizationId, result.data)
      return
    }

    client.connect()
    setWebPubClient(client)

    return () => client.disconnect()
  }, [organizationId, result])

  return { client: webPubClient, error }
}
